import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import { post } from 'app/shared/utils/fetchr';
import { InvestigationActionPayload } from 'app/modules/investigations/types/requests';

export const useReassignAlert = (ids: number[]) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (agentID: number) =>
      post('/investigations/action', {
        object_ids: ids,
        object_type: 'ALERT',
        agent_id: agentID,
      } satisfies InvestigationActionPayload),
    onError: () =>
      dispatch(sendErrorToast('Failed to reassign alert. Please try again.')),
    onSuccess: () => {
      dispatch(sendSuccessToast('Successfully reassigned alert.'));
      ids.forEach((i) => {
        queryClient.invalidateQueries({
          queryKey: ALERT_QUERY_KEYS.getAlert(i, true),
        });
        queryClient.invalidateQueries({
          queryKey: ALERT_QUERY_KEYS.getAlert(i, false),
        });
      });
    },
  });
};
