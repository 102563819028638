import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  U21Card,
  U21Grid,
  U21Loading,
  U21NoData,
  U21InfoItem,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import {
  IconCheckbox,
  IconListSearch,
  IconUserCircle,
} from '@u21/tabler-icons';

import {
  DataSettingFieldType,
  DataSettingsKeyType,
  EntityNativeFields,
} from 'app/modules/dataSettings/responses';
import { DataSettingDataDisplay } from 'app/modules/dataSettings/shared/DataSettingDataDisplay';
import { DataSettingDataLabel } from 'app/modules/dataSettings/shared/DataSettingDataLabel';
import { isFieldEmpty } from 'app/modules/dataSettings/utils';
import { ShortEntityResponse } from 'app/modules/entities/types/responses';
import { selectEntityDataSettingsByNativeKey } from 'app/modules/dataSettings/selectors';

import {
  FinCEN314aBusinessScanResult,
  FinCEN314aBusinessScanResultMatchDetails,
  FinCEN314aPersonScanResult,
  FinCEN314aPersonScanResultMatchDetails,
} from 'app/modules/lists/responses';

const getPersonEntityCardFields = (
  matchDetails: FinCEN314aPersonScanResultMatchDetails,
) => {
  const fullNameMatched =
    !!matchDetails.name_similarity_ratio ||
    !!matchDetails.alias_similarity_ratio;

  return [
    { key: EntityNativeFields.LAST_NAME, matched: fullNameMatched },
    { key: EntityNativeFields.FIRST_NAME, matched: fullNameMatched },
    { key: EntityNativeFields.MIDDLE_NAME, matched: fullNameMatched },
    {
      key: EntityNativeFields.SSN,
      matched: !!matchDetails.ssn_similarity_ratio,
    },
    { key: EntityNativeFields.PHYSICAL_IDS },
    {
      key: EntityNativeFields.DATE_OF_BIRTH,
      matched: !!matchDetails.dob_similarity_ratio,
    },
    {
      key: EntityNativeFields.ADDRESSES,
      matched: !!matchDetails.address_similarity_ratio,
    },
    { key: EntityNativeFields.PHONE_NUMBERS },
    {
      key: EntityNativeFields.EMAIL_ADDRESSES,
      matched: !!matchDetails.email_similarity_ratio,
    },
    {
      key: EntityNativeFields.IP_ADDRESSES,
      matched: !!matchDetails.ip_address_similarity_ratio,
    },
    {
      key: EntityNativeFields.WEBSITE,
      matched: !!matchDetails.website_similarity_ratio,
    },
  ];
};

const getBusinessEntityCardFields = (
  matchDetails: FinCEN314aBusinessScanResultMatchDetails,
) => {
  return [
    {
      key: EntityNativeFields.BUSINESS_NAME,
      matched: !!matchDetails.business_name_similarity_ratio,
    },
    {
      key: EntityNativeFields.DOING_BUSINESS_AS,
      matched: !!matchDetails.dba_name_similarity_ratio,
    },
    { key: EntityNativeFields.CORPORATE_TAX_ID },
    { key: EntityNativeFields.REGISTERED_AT },
    { key: EntityNativeFields.REGISTERED_COUNTRY },
    { key: EntityNativeFields.REGISTERED_STATE },
    {
      key: EntityNativeFields.ADDRESSES,
      matched: !!matchDetails.address_similarity_ratio,
    },
    { key: EntityNativeFields.PHONE_NUMBERS },
    {
      key: EntityNativeFields.EMAIL_ADDRESSES,
      matched: !!matchDetails.email_similarity_ratio,
    },
    {
      key: EntityNativeFields.IP_ADDRESSES,
      matched: !!matchDetails.ip_address_similarity_ratio,
    },
    {
      key: EntityNativeFields.WEBSITE,
      matched: !!matchDetails.website_similarity_ratio,
    },
  ];
};

const getFinCEN314aPersonFileRow = (
  matchDetails: FinCEN314aPersonScanResultMatchDetails,
) => {
  const nameMatched =
    !!matchDetails.name_similarity_ratio &&
    matchDetails.name_similarity_ratio >=
      (matchDetails.alias_similarity_ratio ?? 0);
  const aliasMatched =
    !!matchDetails.alias_similarity_ratio &&
    matchDetails.alias_similarity_ratio >=
      (matchDetails.name_similarity_ratio ?? 0);
  const ssnMatched = !!matchDetails.ssn_similarity_ratio;
  const dobMatched = !!matchDetails.dob_similarity_ratio;
  const electronicAddressMatched =
    !!matchDetails.email_similarity_ratio ||
    !!matchDetails.website_similarity_ratio ||
    !!matchDetails.ip_address_similarity_ratio;
  const addressMatched = !!matchDetails.address_similarity_ratio;

  return [
    { key: 'Tracking number', value: matchDetails.tracking_number },
    { key: 'Last name', value: matchDetails.last_name, matched: nameMatched },
    {
      key: 'First name',
      value: matchDetails.first_name,
      matched: nameMatched,
    },
    {
      key: 'Middle name',
      value: matchDetails.middle_name,
      matched: nameMatched,
    },
    { key: 'Suffix', value: matchDetails.suffix, matched: nameMatched },
    {
      key: 'Alias last name',
      value: matchDetails.alias_last_name,
      matched: aliasMatched,
    },
    {
      key: 'Alias first name',
      value: matchDetails.alias_first_name,
      matched: aliasMatched,
    },
    {
      key: 'Alias middle name',
      value: matchDetails.alias_middle_name,
      matched: aliasMatched,
    },
    {
      key: 'Alias suffix',
      value: matchDetails.alias_suffix,
      matched: aliasMatched,
    },
    { key: 'Number', value: matchDetails.number, matched: ssnMatched },
    {
      key: 'Number type',
      value: matchDetails.number_type,
      matched: ssnMatched,
    },
    { key: 'Date of birth', value: matchDetails.dob, matched: dobMatched },
    { key: 'Street', value: matchDetails.street, matched: addressMatched },
    { key: 'City', value: matchDetails.city, matched: addressMatched },
    { key: 'State', value: matchDetails.state, matched: addressMatched },
    { key: 'Zip', value: matchDetails.zip, matched: addressMatched },
    { key: 'Country', value: matchDetails.country, matched: addressMatched },
    { key: 'Phone number', value: matchDetails.phone },
    {
      key: 'Electronic address',
      value: matchDetails.electronic_address ?? null,
      matched: electronicAddressMatched,
    },
    {
      key: 'Electronic address type',
      value: matchDetails.electronic_address_type ?? null,
      matched: electronicAddressMatched,
    },
  ];
};

const getFinCEN314aBusinessFileRow = (
  matchDetails: FinCEN314aBusinessScanResultMatchDetails,
) => {
  const electronicAddressMatched =
    !!matchDetails.email_similarity_ratio ||
    !!matchDetails.website_similarity_ratio ||
    !!matchDetails.ip_address_similarity_ratio;
  const addressMatched = !!matchDetails.address_similarity_ratio;

  return [
    { key: 'Tracking number', value: matchDetails.tracking_number },
    {
      key: 'Business name',
      value: matchDetails.business_name,
      matched: !!matchDetails.business_name_similarity_ratio,
    },
    {
      key: 'DBA name',
      value: matchDetails.dba_name,
      matched: !!matchDetails.dba_name_similarity_ratio,
    },
    { key: 'Number', value: matchDetails.number },
    { key: 'Number type', value: matchDetails.number_type },
    { key: 'Incorporated', value: matchDetails.incorporated },
    { key: 'Street', value: matchDetails.street, matched: addressMatched },
    { key: 'City', value: matchDetails.city, matched: addressMatched },
    { key: 'State', value: matchDetails.state, matched: addressMatched },
    { key: 'Zip', value: matchDetails.zip, matched: addressMatched },
    { key: 'Country', value: matchDetails.country, matched: addressMatched },
    { key: 'Phone number', value: matchDetails.phone },
    {
      key: 'Electronic address',
      value: matchDetails.electronic_address ?? null,
      matched: electronicAddressMatched,
    },
    {
      key: 'Electronic address type',
      value: matchDetails.electronic_address_type ?? null,
      matched: electronicAddressMatched,
    },
  ];
};

const EntityCard = ({
  entity,
  fields,
  isEmptyFieldShown,
  title,
}: {
  entity: ShortEntityResponse;
  fields: { key: EntityNativeFields; matched?: boolean }[];
  isEmptyFieldShown: boolean;
  title: string;
}) => {
  const dataSettings = useSelector(selectEntityDataSettingsByNativeKey);
  const { formatted_data: data } = entity;

  const matchedProps = {
    color: 'error' as const,
    icon: <IconCheckbox aria-hidden />,
    tooltip: 'Matched against FinCEN 314(a) matchlist record',
  };

  return (
    <U21Card title={title} subheader="Unit21 Entity" icon={<IconUserCircle />}>
      <U21Spacer dividers>
        {fields.map(({ key, matched }) => {
          const dataSetting = dataSettings[key];
          const dsData = dataSetting ? data[dataSetting.id] : undefined;

          if (!isEmptyFieldShown && isFieldEmpty(dsData)) {
            return null;
          }

          const isAddresses =
            dataSetting?.data_type === DataSettingFieldType.LIST &&
            dataSetting?.key_type === DataSettingsKeyType.NATIVE &&
            dataSetting?.native_key === 'addresses';

          return (
            <U21InfoItem
              key={key}
              label={
                dataSetting ? (
                  <StyledDataSettingDataLabel dataSetting={dataSetting} />
                ) : null
              }
              width={150}
            >
              {(() => {
                if (!data) return null;
                return (
                  <DataSettingDataDisplay
                    dataSetting={dataSetting}
                    formattedData={data}
                    dataDisplayProps={
                      matched
                        ? ({
                            ENUM: matchedProps,
                            NUMBER: matchedProps,
                            DATE: matchedProps,
                            DATE_TIME: matchedProps,
                            TEXT: matchedProps,
                            LIST: isAddresses
                              ? {
                                  displayFunc: (val: string) => (
                                    <U21Typography
                                      variant="body2"
                                      key={val}
                                      {...matchedProps}
                                    >
                                      {val}
                                    </U21Typography>
                                  ),
                                }
                              : matchedProps,
                            LINK: matchedProps,
                            ANY: matchedProps,
                          } as const)
                        : {}
                    }
                  />
                );
              })()}
            </U21InfoItem>
          );
        })}
      </U21Spacer>
    </U21Card>
  );
};

const FinCEN314aFileRowCard = ({
  data,
  isEmptyFieldShown,
  title,
}: {
  data: { key: string; value: string | null; matched?: boolean }[];
  isEmptyFieldShown: boolean;
  title: string;
}) => {
  const matchedProps = {
    color: 'error' as const,
    icon: <IconCheckbox aria-hidden />,
    tooltip: 'Matched against Unit21 entity',
  };

  return (
    <StyledCard
      title={title}
      subheader="FinCEN 314(a) matchlist record"
      icon={<IconListSearch />}
    >
      <U21Spacer dividers>
        {data.map(({ key, value, matched }) => {
          if (isEmptyFieldShown || value !== null) {
            return (
              <U21InfoItem
                key={key}
                label={key}
                width={150}
                valueProps={matched ? matchedProps : {}}
              >
                {value}
              </U21InfoItem>
            );
          }
          return null;
        })}
      </U21Spacer>
    </StyledCard>
  );
};

export const FinCEN314aMatch = ({
  entity,
  isEmptyFieldShown,
  isLoading,
  scanResult,
}: {
  entity: ShortEntityResponse | undefined;
  isEmptyFieldShown: boolean;
  isLoading: boolean;
  scanResult:
    | FinCEN314aPersonScanResult
    | FinCEN314aBusinessScanResult
    | undefined;
}) => {
  if (isLoading) {
    return <U21Loading loading />;
  }

  if (entity === undefined) {
    return <U21NoData>No entity found.</U21NoData>;
  }

  if (scanResult === undefined) {
    return <U21NoData>No match found.</U21NoData>;
  }

  let entityTitle: string;
  let entityCardFields: { key: EntityNativeFields; matched?: boolean }[];
  let finCENFileRowTitle: string;
  let finCEN314aFileRow: {
    key: string;
    value: string | null;
    matched?: boolean;
  }[];

  if (scanResult.type === 'PERSON') {
    const { match_details: matchDetails } = scanResult;

    entityTitle = entity.name_readable;
    entityCardFields = getPersonEntityCardFields(matchDetails);

    finCENFileRowTitle =
      [
        matchDetails.first_name,
        matchDetails.middle_name,
        matchDetails.last_name,
        matchDetails.suffix,
      ]
        .join(' ')
        .trim() || matchDetails.tracking_number;
    finCEN314aFileRow = getFinCEN314aPersonFileRow(matchDetails);
  } else {
    const { match_details: matchDetails } = scanResult;

    entityTitle = entity.name_readable; // TO DO: Set better title for business entity card
    entityCardFields = getBusinessEntityCardFields(matchDetails);

    finCENFileRowTitle =
      (matchDetails.business_name ?? matchDetails.dba_name) ||
      matchDetails.tracking_number;
    finCEN314aFileRow = getFinCEN314aBusinessFileRow(matchDetails);
  }

  return (
    <U21Spacer>
      <U21Grid columns={2} spacing={1}>
        <EntityCard
          entity={entity}
          fields={entityCardFields}
          isEmptyFieldShown={isEmptyFieldShown}
          title={entityTitle}
        />
        <FinCEN314aFileRowCard
          data={finCEN314aFileRow}
          isEmptyFieldShown={isEmptyFieldShown}
          title={finCENFileRowTitle}
        />
      </U21Grid>
    </U21Spacer>
  );
};

// Needed to ensure subheader has ellipsis when page is too small
const StyledCard = styled(U21Card)`
  & .MuiCardHeader-content {
    width: 100%;
  }
`;

const StyledDataSettingDataLabel = styled(DataSettingDataLabel).attrs({
  variant: 'body2',
  color: 'text.secondary',
})`
  margin-top: 2px;
`;
