import { CommentResponse } from 'app/modules/comments/types';

import { COMMENT_QUERY_KEYS } from 'app/modules/comments/queries/keys';

import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';

const getComments = (id: number): Promise<CommentResponse[]> =>
  get(`/comments/${id}`);

export const useComments = (id: number) => {
  return useQuery({
    queryKey: COMMENT_QUERY_KEYS.getComment(id),
    queryFn: () => getComments(id),
    initialData: [],
    enabled: id > 0,
    meta: { errorMessage: 'Failed to get comments.' },
    select: (data) => data.slice().reverse(),
  });
};
