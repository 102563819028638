import { post } from 'app/shared/utils/fetchr';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import {
  InvestigationsAlertResponse,
  InvestigationsPayload,
} from 'app/modules/investigations/types';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';
import { useEffect, useMemo, useRef } from 'react';
import { selectDLEnabledForAlertDetails } from 'app/shared/featureFlags/selectors';
import { DEFAULT_OFFSET } from 'app/shared/pagination/constants';
import { FullAlertsCachedResponse } from 'app/modules/alerts/types/responses';
import {
  ColdStorageLoadingStatus,
  COLD_STORAGE_WAIT_TIME,
  COLD_STORAGE_MAX_RETRIES,
  getLoadingFromColdStorageState,
} from 'app/shared/utils/fastArchHelpers';
import { GetAlertsCachedListPayload } from 'app/modules/alerts/requests';

const fetchAlert = (alertId: number) =>
  post<FullAlertsCachedResponse>(`/alerts/cached/list`, {
    alert_ids: [alertId],
    short: false,
    offset: DEFAULT_OFFSET,
    limit: 1,
  } satisfies GetAlertsCachedListPayload);

export const useGetAlert = (
  id: number,
  options: Pick<
    UseQueryOptions<InvestigationsAlertResponse<false>>,
    'enabled'
  > = {},
) => {
  const { enabled = true } = options;
  const isDLEnabledForAlertDetails = useSelector(
    selectDLEnabledForAlertDetails,
  );
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const dataUpdateCountRef = useRef(0);
  const query = useQuery<
    FullAlertsCachedResponse | InvestigationsAlertResponse<false>
  >({
    refetchInterval: ({ state: { data } }) => {
      /*
        We might want to move the refetch interval to the hook definition once we are sure the new cache tables work correctly, for now only using it on this tab
        Make sure we got only 1 alert ID, if it's loading from cold storage refetch after waiting some time as long as we don't run out of retries
      */
      if (isDLEnabledForAlertDetails) {
        if (data?.alerts.length === 1) {
          const alertId = data?.alerts[0].id;
          const loadingStatus = getLoadingFromColdStorageState(
            alertId,
            data?.data_loader,
          );
          const cgdoStillLoading = Object.values(loadingStatus).some((status) =>
            [
              ColdStorageLoadingStatus.LOADING,
              ColdStorageLoadingStatus.LOADING_TIMEOUT,
            ].includes(status),
          );

          // Retry if we haven't hit the max retries and the CGDO is still loading
          if (
            dataUpdateCountRef.current < COLD_STORAGE_MAX_RETRIES &&
            cgdoStillLoading
          ) {
            return COLD_STORAGE_WAIT_TIME;
          }
        }
      }
      // Do not retry by default
      return false;
    },
    enabled: hasReadAlertsPermission && enabled,
    queryKey: ALERT_QUERY_KEYS.getAlert(id, isDLEnabledForAlertDetails),
    queryFn: () => {
      if (isDLEnabledForAlertDetails) {
        dataUpdateCountRef.current += 1;
        return fetchAlert(id);
      }
      return post<InvestigationsAlertResponse<false>>(
        '/investigations/retrieve/alert',
        {
          short: false,
          object_type: 'ALERT',
          object_ids: [id],
        } satisfies InvestigationsPayload,
      );
    },
  });

  const coldStorageLoading = useMemo(
    () =>
      getLoadingFromColdStorageState(
        id,
        isDLEnabledForAlertDetails ? query.data?.data_loader : undefined,
      ),
    [id, query.data?.data_loader, isDLEnabledForAlertDetails],
  );

  useEffect(() => {
    // Reset update count when the alert ID changes
    dataUpdateCountRef.current = 0;
  }, [id]);

  return {
    ...query,
    alert: query.data?.alerts[0],
    coldStorageLoading,
  };
};
