import {
  DataLoaderJobType,
  DataLoaderState,
  DataLoaderStatus,
} from 'app/modules/investigations/types/responses';

export enum ColdStorageLoadingStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  LOADING_TIMEOUT = 'LOADING_TIMEOUT', // We are still loading but it's been more than the threshold
}

export const LOADING_TIME_THRESHOLD_IN_SECONDS = 60 * 5; // 5 minutes
export const COLD_STORAGE_WAIT_TIME = 2 * 60 * 1000; // 2 minutes
export const COLD_STORAGE_MAX_RETRIES = 3;

// TODO SC-88399 Call these functions on useGetFinCENSAR, useGetCase similar to useGetAlert
export const getLoadingFromColdStorageState = (
  alertId: number,
  beState: DataLoaderState<'ALERT'> | undefined,
): {
  [key in DataLoaderJobType]: ColdStorageLoadingStatus;
} => {
  const loadingState: {
    [key in DataLoaderJobType]: ColdStorageLoadingStatus;
  } = {
    txn_event: ColdStorageLoadingStatus.LOADED,
    instrument: ColdStorageLoadingStatus.LOADED,
  };

  const alertBEState = beState?.article_ids[alertId];
  if (alertBEState) {
    Object.keys(alertBEState).forEach((key: DataLoaderJobType) => {
      if (
        alertBEState[key].seconds_since_execution !== null &&
        [DataLoaderStatus.LOADING, DataLoaderStatus.TO_QUEUE].includes(
          alertBEState[key].status,
        )
      ) {
        // Update the loading state to Loading based on the threshold
        loadingState[key] =
          alertBEState[key].seconds_since_execution <
          LOADING_TIME_THRESHOLD_IN_SECONDS
            ? ColdStorageLoadingStatus.LOADING
            : ColdStorageLoadingStatus.LOADING_TIMEOUT;
      }
    });
  }
  return loadingState;
};
