import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

// Models
import { EntitySummary } from 'app/modules/entities/models';
import { InvestigationType } from 'app/modules/investigations/models';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';

// Selectors
import {
  selectAlertTableConfig,
  selectEntityTableConfig,
  selectFilingsTableConfig,
  selectActionEventTableConfig,
} from 'app/shared/CustomConfig/selectors';
import { selectInvestigation } from 'app/modules/investigations/selectors';
import {
  selectHasReadAlertsPermission,
  selectHasReadEntitiesPermissions,
  selectHasReadSARsPermissions,
  selectHasReadEventsPermission,
  selectAgent,
  selectHasReadAssignmentsPermissions,
} from 'app/modules/session/selectors';
import { selectClassifiedQueuesEnabled } from 'app/shared/featureFlags/selectors';

// Components
import { IconLayoutColumns } from '@u21/tabler-icons';
import {
  U21Button,
  U21Section,
  U21Table,
  U21TitleCountLabel,
} from 'app/shared/u21-ui/components';

// Utils
import { createTableColumnConfig } from 'app/shared/utils/table';
import { isQueueAccessible } from 'app/modules/queues/utils';

// Constants
import {
  ALERT_COLUMN_CONFIG,
  AlertTableType,
} from 'app/modules/alerts/columns';
import { ENTITY_COLUMN_CONFIG } from 'app/modules/entitiesRefresh/columns';
import {
  SAR_COLUMN_CONFIG,
  SARTableDataType,
} from 'app/modules/fincenSar/columns';
import { REPORT_TYPE_PATH_MAP } from 'app/modules/fincenSar/constants/shared';
import { ACTION_EVENTS_COLUMN_CONFIG } from 'app/modules/actionEvents/columns';

// Actions
import { toggleTableConfigSidebar } from 'app/modules/sidebar/slice';
import {
  selectSidebarActionEventID,
  selectSidebarAlertID,
  selectSidebarEntityID,
  selectSidebarFinCENSARID,
} from 'app/modules/sidebar/selectors';
import {
  useToggleActionEventSidebar,
  useToggleAlertSidebar,
  useToggleEntitySidebar,
  useToggleFinCENSARSidebar,
} from 'app/modules/sidebar/hooks';
import { ReportType } from 'app/modules/fincenSar/models';
import { useHistory } from 'react-router-dom';
import { isCmdCtrlClick } from 'app/shared/utils/keyboard';
import { openInNewTab } from 'app/shared/utils/routes';

export enum FormTabTableKeys {
  ALERTS = 'alerts',
  ENTITIES = 'entities',
  SARS = 'sars',
  ACTION_EVENTS = 'action_events',
}

interface OwnProps {
  keyType: FormTabTableKeys;
}

export const FormTabTable = (props: OwnProps) => {
  const { keyType } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const details = useSelector(selectInvestigation(InvestigationType.CASE));
  const alertTableConfig = useSelector(selectAlertTableConfig);
  const entityTableConfig = useSelector(selectEntityTableConfig);
  const actionEventTableConfig = useSelector(selectActionEventTableConfig);
  const sarFilingTableConfig = useSelector(selectFilingsTableConfig);
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const hasReadEntitiesPermission = useSelector(
    selectHasReadEntitiesPermissions,
  );
  const hasReadEventsPermissions = useSelector(selectHasReadEventsPermission);
  const hasReadSARPermissions = useSelector(selectHasReadSARsPermissions);
  const hasReadAssignmentsPermission = useSelector(
    selectHasReadAssignmentsPermissions,
  );
  const classifiedQueuesEnabled = useSelector(selectClassifiedQueuesEnabled);
  const sessionAgent = useSelector(selectAgent);
  const sidebarAlertID = useSelector(selectSidebarAlertID);
  const sidebarEntityID = useSelector(selectSidebarEntityID);
  const sidebarActionEventID = useSelector(selectSidebarActionEventID);
  const sidebarFinCENSARID = useSelector(selectSidebarFinCENSARID);

  const toggleAlertSidebar = useToggleAlertSidebar();
  const toggleFinCENSARSidebar = useToggleFinCENSARSidebar();
  const toggleEntitySidebar = useToggleEntitySidebar();
  const toggleActionEventSidebar = useToggleActionEventSidebar();

  const { label, title, tableProps, tableType } = useMemo(() => {
    switch (keyType) {
      case FormTabTableKeys.ALERTS:
        return {
          label: 'alert',
          title: 'Alerts',
          tableProps: {
            columns: [
              ...createTableColumnConfig<AlertTableType>(
                alertTableConfig,
                ALERT_COLUMN_CONFIG,
              ),
            ],
            highlighted: sidebarAlertID ? [sidebarAlertID] : undefined,
            isRowClickable: (row: AlertTableType) =>
              !classifiedQueuesEnabled ||
              isQueueAccessible(
                hasReadAssignmentsPermission,
                sessionAgent.accessible_queues,
                row.queue_id,
                row.queue_access_type,
              ),
            onRowClick: hasReadAlertsPermission
              ? (id: number, row: AlertTableType, e: KeyboardEvent) => {
                  toggleAlertSidebar(id, e);
                }
              : undefined,
          },
          tableType: TableConfigType.ALERT_TABLE,
        };
      case FormTabTableKeys.ENTITIES:
        return {
          label: 'entity',
          title: 'Entities',
          tableProps: {
            columns: [
              ...createTableColumnConfig<EntitySummary>(
                entityTableConfig,
                ENTITY_COLUMN_CONFIG,
              ),
            ],
            highlighted: sidebarEntityID ? [sidebarEntityID] : undefined,
            onRowClick: hasReadEntitiesPermission
              ? (id: number, _, e: KeyboardEvent) => {
                  toggleEntitySidebar(id, e);
                }
              : undefined,
          },
          tableType: TableConfigType.ENTITY_TABLE,
        };
      case FormTabTableKeys.ACTION_EVENTS:
        return {
          label: 'action',
          title: 'Action Events',
          tableProps: {
            columns: [
              ...createTableColumnConfig<ShortActionEventResponse>(
                actionEventTableConfig,
                ACTION_EVENTS_COLUMN_CONFIG,
              ),
            ],
            highlighted: sidebarActionEventID
              ? [sidebarActionEventID]
              : undefined,
            onRowClick: hasReadEventsPermissions
              ? (id: number, _, e: KeyboardEvent) => {
                  toggleActionEventSidebar(id, e);
                }
              : undefined,
          },
          tableType: TableConfigType.ACTION_EVENT_TABLE,
        };
      // lump default with the last option because it's impossible
      // for it to be anything else based on typing
      case FormTabTableKeys.SARS:
      default:
        return {
          label: 'filings',
          title: 'Filings',
          tableProps: {
            columns: [
              ...createTableColumnConfig<SARTableDataType>(
                sarFilingTableConfig,
                SAR_COLUMN_CONFIG,
              ),
            ],
            highlighted: sidebarFinCENSARID ? [sidebarFinCENSARID] : undefined,
            isRowClickable: (row: SARTableDataType) =>
              !classifiedQueuesEnabled ||
              row.queue === null ||
              isQueueAccessible(
                hasReadAssignmentsPermission,
                sessionAgent.accessible_queues,
                row.queue.id,
                row.queue?.access_type,
              ),
            onRowClick: hasReadSARPermissions
              ? (id: number, sar: SARTableDataType, e: KeyboardEvent) => {
                  const { report_type: reportType } = sar;
                  if (reportType === ReportType.FINCEN_SAR) {
                    toggleFinCENSARSidebar(id, e);
                  } else {
                    const route = REPORT_TYPE_PATH_MAP[reportType].replace(
                      ':id',
                      String(id),
                    );
                    if (isCmdCtrlClick(e)) {
                      openInNewTab(route);
                    } else {
                      history.push(route);
                    }
                  }
                }
              : undefined,
          },
          tableType: TableConfigType.SAR_TABLE,
        };
    }
  }, [
    actionEventTableConfig,
    alertTableConfig,
    classifiedQueuesEnabled,
    entityTableConfig,
    hasReadAlertsPermission,
    hasReadAssignmentsPermission,
    hasReadEntitiesPermission,
    hasReadEventsPermissions,
    hasReadSARPermissions,
    keyType,
    history,
    sarFilingTableConfig,
    sessionAgent.accessible_queues,
    sidebarActionEventID,
    sidebarAlertID,
    sidebarEntityID,
    sidebarFinCENSARID,
    toggleActionEventSidebar,
    toggleAlertSidebar,
    toggleEntitySidebar,
    toggleFinCENSARSidebar,
  ]);

  const data = details?.[keyType] ?? [];

  const handleTableConfig = () => {
    dispatch(toggleTableConfigSidebar({ tableConfigType: tableType }));
  };

  return (
    <U21Section
      action={
        <U21Button
          onClick={handleTableConfig}
          startIcon={<IconLayoutColumns />}
        >
          Choose Columns
        </U21Button>
      }
      title={
        <U21TitleCountLabel count={data?.length ?? 0} label={label}>
          {title}
        </U21TitleCountLabel>
      }
    >
      <U21Table data={data} {...tableProps} />
    </U21Section>
  );
};
