import { useGetAlert } from 'app/modules/alerts/queries/useGetAlert';
import { useComments } from 'app/modules/comments/queries/useComments';
import { useMemo } from 'react';
import { AlertType } from 'app/modules/alerts/types';
import {
  dedupComments,
  formatChainalysisComments,
} from 'app/modules/comments/utils';

export const useGetAlertComments = (
  id: number,
  { reverse = true }: { reverse?: boolean } = {},
) => {
  const {
    alert,
    isError: isAlertError,
    isLoading: alertLoading,
    refetch: refetchAlert,
  } = useGetAlert(id);

  const {
    data: comments,
    isError: isCommentsError,
    isLoading: commentsLoading,
    refetch: refetchComments,
  } = useComments(id);

  const allComments = useMemo(() => {
    const mergedComments =
      alert?.alert_type === AlertType.CHAINALYSIS
        ? dedupComments(
            comments,
            formatChainalysisComments(
              alert?.content?.statusesAndComments || [],
            ),
          )
        : comments;
    return mergedComments.sort((a, b) => {
      const aDate = new Date(a.created_at || '').getTime();
      const bDate = new Date(b.created_at || '').getTime();
      return reverse ? bDate - aDate : aDate - bDate;
    });
  }, [alert, comments, reverse]);

  return {
    alert,
    comments: allComments,
    isAlertError,
    isCommentsError,
    isLoading: alertLoading || commentsLoading,
    refetchAlert,
    refetchComments,
  };
};
